import React from "react"
import { SidebarLayout } from "../components/layout"
import { format } from "date-fns"

export const ListView: React.FC<any> = ({ pageContext }) => {
  const { competition, page, sidebar } = pageContext
  const startDate = format(new Date(competition.start_date), "iii do LLL yyyy")
  const endDate = format(new Date(competition.end_date), "iii do LLL yyyy")

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug: pageContext.competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <div className="mb-8">
          <h2 className="font-primary font-black text-3xl">
            {competition.title.text}
          </h2>
          <time>
            {startDate} - {endDate}
          </time>
        </div>
        <div style={{ maxHeight: "900px", overflow: "scroll" }}>
          <iframe
            src={`${page.fileName}`}
            style={{ width: "100%", height: "900px" }}
          ></iframe>
        </div>
      </div>
    </SidebarLayout>
  )
}

export default ListView
